.footer-cta {
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 84px var(--block-margin);
    background: var(--color-primary);

    > h3 {
      line-height: 60px;
      font-size: 40px;
      margin: 0;
    }

    > p {
      max-width: 600px;
      line-height: 32px;
      margin: 28px auto 0;
      font-size: 20px;
    }

    > a:last-child {
      margin: 40px 0 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    > div {
      padding: 100px 0;

      > h3 {
        line-height: 60px;
        font-size: 52px;
      }
    }
  }
}
