@import '../scss/mixins.scss';

.h2 {
  @include text('h1');
  margin: 0;
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    margin: 16px auto 0;
    background: var(--color-primary);
  }

  @media only screen and (min-width: 375px) {
    line-height: 60px;
    font-size: 40px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 52px;
  }
}
