@import '../../scss/mixins.scss';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;

  &__info {
    margin: 24px 0 0;
    padding: 0 var(--block-margin);

    > div:first-child {
      > svg:first-child {
        width: 80px;
        height: 60px;
      }

      > h1 {
        max-width: 440px;
        line-height: 38px;
        margin: 32px auto 0;
        font-size: 28px;
        font-weight: 400;

        > span {
          white-space: nowrap;
        }
      }

      > p {
        @include text('p1');
        max-width: 540px;
        margin: 32px auto 0;
        color: var(--color-dark-300);
      }

      > a:last-child {
        margin: 24px 0 0;
      }
    }
  }

  &__iosapp {
    margin: 40px var(--block-margin) 0;
    padding: 40px 0 0;
    border-radius: 24px;
    background: var(--color-dark-1800);

    > div {
      > p {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
      }

      > a {
        display: inline-flex;
        align-items: center;
        margin: 20px 0 0;
        padding: 4px 10px 4px 4px;
        border: 1px solid var(--color-dark-000);
        border-radius: 4px;
        color: var(--color-dark-000);
        background: var(--color-dark-1800);
        text-align: left;
        text-decoration: none;

        > svg {
          flex-shrink: 0;
          width: 24px;
          height: 20px;
          margin: 0 2px 0 0;
          fill: var(--color-dark-000);
        }

        > p {
          line-height: 13px;
          margin: 0;
          font-size: 7px;

          > b {
            display: block;
            font-size: 14px;
          }
        }
      }
    }

    > picture > img {
      margin: 40px auto 0;
      max-width: 100%;
    }
  }

  &__dishes {
    overflow: hidden;
    max-width: 1190px;
    min-height: 550px;
    margin: 64px auto 0;

    > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      > picture {
        opacity: 0;

        > img {
          width: 100%;
        }

        &:nth-child(15) ~ picture {
          display: none;
        }
      }
    }


    &--animated {
      > div > picture {
        animation: bounceIn 1.5s 1s forwards;

        &:nth-child(7n + 4) {
          animation-delay: 0.75s;
        }

        &:nth-child(5n + 3) {
          animation-delay: 0.5s;
        }

        &:nth-child(6n + 1) {
          animation-delay: 0.25s;
        }

        &:nth-child(4n + 1) {
          animation-delay: 0s;
        }
      }
    }
  }

  @media only screen and (min-width: 375px) {
    &__iosapp {
      margin-top: 80px;

      > picture > img {
        max-width: 340px;
      }
    }
  }

  @media only screen and (min-width: 500px) {
    &__info {
      margin-top: 64px;

      > div:first-child {
        > h1 {
          line-height: 44px;
          margin-top: 48px;
          font-size: 32px;
        }

        > button {
          margin: 48px 0 0;
        }
      }
    }

    &__dishes > div {
      grid-template-columns: repeat(4, 1fr);

      > picture {
        &:nth-child(15) ~ picture {
          display: block;
        }

        &:nth-child(20) ~ picture {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-width: 625px) {
    &__dishes > div {
      grid-template-columns: repeat(5, 1fr);

      > picture {
        &:nth-child(20) ~ picture {
          display: block;
        }

        &:nth-child(25) ~ picture {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-width: 750px) {
    &__iosapp {
      position: relative;
      width: 656px;
      margin-top: 132px;
      padding: 40px;
      text-align: left;

      > picture > img {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
      }
    }

    &__dishes > div {
      grid-template-columns: repeat(6, 1fr);

      > picture {
        &:nth-child(25) ~ picture {
          display: block;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    &__info > div:first-child {
      > svg:first-child {
        width: 120px;
        height: 90px;
      }

      > div:last-child {
        margin: 40px 0 0;
      }
    }

    &__dishes > div {
      grid-template-columns: repeat(7, 1fr);

      > picture {
        &:nth-child(30) ~ picture {
          display: block;
        }

        &:nth-child(35) ~ picture {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-width: 1329px) {
    flex-direction: row;
    align-items: flex-start;
    margin: 0;
    text-align: left;

    &__info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 96px 0 0;
      padding: 0;

      > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 720px;

        > h1 {
          max-width: 620px;
          line-height: 64px;
          margin: 96px 0 0;
          font-size: 48px;
        }

        > p {
          line-height: 32px;
          margin: 40px 0 0;
          font-size: 20px;
        }

        > a:last-child {
          margin: 64px 0 0;
        }
      }
    }

    &__iosapp {
      margin-top: 150px;
    }

    &__dishes {
      width: calc(50% - 130px);
      max-width: none;
      min-height: 820px;
      margin: 0;

      > div {
        grid-template-columns: repeat(5, 1fr);

        > picture {
          &:first-child {
            grid-column: 2 / span 2;
            grid-row: 4 / span 2;
          }

          &:nth-child(2) {
            grid-column: 4 / span 2;
            grid-row: 2 / span 2;
          }

          &:nth-child(29) ~ picture {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1920px) {
    &__dishes > div {
      max-width: 1020px;
      grid-template-columns: repeat(6, 1fr);

      > picture {
        &:nth-child(29) ~ picture {
          display: block;
        }
      }
    }
  }
}
