@import '../../scss/mixins.scss';

.footer {
  padding: 64px var(--block-margin) 24px;
  text-align: center;
  background: var(--color-dark-1800);

  > svg:first-child {
    width: 120px;
    height: 90px;
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 0;

    > a {
      width: 32px;
      height: 32px;
      margin: 0 12px;

      > svg {
        width: 32px;
        height: 32px;
        fill: var(--color-dark-600);
      }
    }
  }

  &__documents {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0 0;
    padding: 14px 0;

    > div {
      margin: 6px 0;

      > a {
        display: inline-block;
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        padding: 8px 16px;
        text-decoration: none;
        color: var(--color-dark-300);
        transition: color 0.3s;

        &:hover {
          color: var(--color-dark-000);
        }
      }
    }
  }

  &__legal {
    p {
      @include text('p2');
      margin: 0 0 12px;
      color: var(--color-dark-700);

      &:last-child {
        margin: 0;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    &__documents {
      flex-direction: row;
      margin: 24px 0 0;
      padding: 0;

      > div {
        margin: 0 12px;
      }
    }

    &__legal {
      margin: 32px 0 0;
    }
  }
}
