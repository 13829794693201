@import '../../scss/mixins.scss';

.principles {
  overflow: hidden;
  max-width: 1328px;
  margin: 120px auto 0;
  padding: 0 var(--block-margin);
  text-align: center;

  > h2 {
    @include text('h1');

    &::after {
      content: '';
      display: block;
      width: 100px;
      height: 4px;
      margin: 16px auto 0;
      background: var(--color-primary);
    }
  }

  &__item {
    max-width: 520px;
    margin: 120px auto 0;

    > div:first-child {
      opacity: 0;
      margin: 0 auto;

      img {
        height: 192px;
        margin: 0 auto;
      }
    }

    > div:last-child {
      > h3 {
        @include text('h2');
        margin: 40px 0 0;
      }

      > p {
        line-height: 32px;
        margin: 20px 0 0;
        font-size: 18px;
        color: var(--color-dark-300);
      }
    }

    h2 + & {
      margin-top: 64px;
    }

    &--animated {
      > div:first-child {
        animation: fadeInTopLeft 0.75s forwards;
      }

      &:nth-child(2n + 1) > div:first-child {
        animation-name: fadeInTopRight;
      }
    }
  }

  @media only screen and (min-width: 375px) {
    > h2 {
      line-height: 60px;
      font-size: 40px;
    }

    &__item > div:first-child > img {
      height: 228px;
    }

    &__item > div:last-child > h3 {
      @include text('h1');
    }
  }

  @media only screen and (min-width: 1024px) {
    > h2 {
      font-size: 52px;
    }

    &__item {
      display: flex;
      align-items: center;
      max-width: none;
      margin: 150px auto 0;
      text-align: left;

      > div {
        flex: 1;
      }

      > div:first-child {
        margin: 0 60px 0 0;
        display: flex;
        justify-content: center;

        img {
          height: 300px;
        }
      }

      > div:last-child {
        > h3 {
          line-height: 60px;
          margin: 0;
          font-size: 40px;
        }

        > p {
          line-height: 36px;
          margin: 24px 0 0;
          font-size: 20px;
        }
      }

      h2 + & {
        margin-top: 64px;
      }

      &:nth-child(2n + 1) > div:first-child {
        order: 1;
        margin: 0 0 0 60px;
      }
    }
  }

  @media only screen and (min-width: 1329px) {
    &__item {
      margin: 160px auto 0;

      > div:first-child {
        margin: 0 120px 0 0;

        img {
          height: 400px;
        }
      }

      &:nth-child(2n + 1) > div:first-child {
        margin: 0 0 0 120px;
      }
    }
  }
}
