.button {
  display: inline-block;
  vertical-align: middle;
  line-height: 28px;
  padding: 14px 48px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 20px;
  color: var(--color-dark-000);
  background: var(--color-primary);
  border: none;
  cursor: pointer;
  transition: opacity .15s ease;
  outline: none;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &--inverse {
    color: var(--color-dark-1700);
    background: var(--color-dark-000);
  }
}
