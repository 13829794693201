@import '../../scss/mixins.scss';

.visual {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  margin: 120px 0;

  &__note {
    transition: 0.3s;
    display: none;
    flex-direction: column;
    align-items: center;
    line-height: 32px;
    margin: 64px 0 0;
    font-size: 20px;
    text-align: center;

    div:last-child {
      margin: 48px 0 0;

      div {
        width: 20px;
        height: 20px;
        border-bottom: 5px solid #AAA3BF;
        border-right: 5px solid #AAA3BF;
        transform: rotate(45deg);
        margin: -10px;
        animation: arrowScrollDown 2s infinite;

        &:nth-child(2) {
          animation-delay: -0.2s;
        }

        &:nth-child(3) {
          animation-delay: -0.4s;
        }
      }
    }
  }

  &__content {
    margin: 80px 0 0;
    display: none;
    align-items: flex-start;
  }

  &__dish {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: -48px;
    right: -58px;
    transition: transform 0.1s, opacity 0.3s, visibility 0.3s;

    > img {
      width: 160px;
      height: 160px;
    }
  }

  &__lines {
    position: relative;
    width: 514px;
    margin: 0 auto;
  }

  &__line {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    display: flex;
    width: 514px;

    > svg {
      height: 64px;
      width: 100%;
      fill: var(--color-primary);
      visibility: hidden;
    }

    &:nth-child(2n) {
      position: absolute;
      z-index: 1;
      left: -25px;
      transform: rotate(26deg);
      width: 564px;
    }

    &:nth-child(2n + 1) {
      transform: rotate(180deg);
    }

    &:nth-child(2) {
      top: 110px;
    }

    &:nth-child(3) {
      margin-top: 156px;
    }

    &:nth-child(4) {
      top: 330px;
    }
  }

  &__statuses:first-child {
    margin: 0 64px 0 0;
  }

  &__statuses:last-child {
    margin: 0 0 0 64px;
  }

  &__mobile-content {
    position: relative;
    max-width: 360px;
    margin: 64px auto 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 11px;
      width: 8px;
      margin: auto;
      border-radius: 12px;
      background: var(--color-primary);
    }

    .visual__status {
      opacity: 0;
      display: flex;
      align-items: center;
      margin: 0 0 32px 0;
      z-index: 1;

      > .visual__checkmark {
        opacity: 0;
        position: relative;
        z-index: 1;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: 0 8px 0 0;
        border-radius: 50%;
        background: var(--color-dark-000);

        > svg {
          width: 28px;
          height: 28px;
          fill: var(--color-primary);
        }
      }

      > svg {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        margin: 0 8px 0 0;
      }

      > p {
        @include text('h4');
      }
    }

    &--animated {
      .visual__status {
        animation: fadeInTopRight 0.5s forwards 0.5s;

        > .visual__checkmark {
          animation: fadeIn 0.25s forwards 1s;
        }

        &:nth-child(2) {
          animation-delay: 1.25s;

          > .visual__checkmark {
            animation-delay: 1.75s;
          }
        }

        &:nth-child(3) {
          animation-delay: 2s;

          > .visual__checkmark {
            animation-delay: 2.5s;
          }
        }

        &:nth-child(4) {
          animation-delay: 2.75s;

          > .visual__checkmark {
            animation-delay: 3.25s;
          }
        }

        &:nth-child(5) {
          animation-delay: 3.5s;

          > .visual__checkmark {
            animation-delay: 4s;
          }
        }
      }
    }
  }

  &--active {
    > div {
      position: fixed;
      z-index: 1;
      top: 32px;
      left: 0;
      right: 0;
    }

    .visual__note {
      position: fixed;
      top: 106px;
      left: 0;
      right: 0;
      opacity: 0;
      visibility: hidden;
    }

    .visual__line,
    .visual__dish {
      visibility: visible;
      opacity: 1;
    }

    .visual__status.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &--end {
    justify-content: flex-end;

    > div {
      position: static;
      margin-bottom: -50px;
    }

    .visual__dish,
    .visual__status,
    .visual__status.active {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__line--active > svg {
    visibility: visible;
  }

  @media only screen and (min-width: 375px) {
    &__mobile-content {
      .visual__status {
        > .visual__checkmark {
          margin: 0 16px 0 0;
        }

        > svg {
          width: 80px;
          height: 80px;
          margin: 0 16px 0 0;
        }
      }
    }
  }

  @media only screen and (min-width: 920px) {
    height: 3140px;
    margin: 120px 0 0;
    padding: 0 0 150px;

    > div {
      width: 882px;
      margin: 0 auto;
    }

    &__note,
    &__content {
      display: flex;
    }

    &__status {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      width: 120px;
      margin: 0 0 48px;
      text-align: center;

      > svg {
        margin: -28px 0 12px;
        width: 120px;
        height: 120px;
      }

      > p {
        margin: 0;
        height: 56px;
      }

      &:last-child {
        margin: 0;
      }
    }

    &__mobile-content {
      display: none;
    }
  }
}
