@mixin text($code) {
  @if $code == 'h1' {
    line-height: 40px;
    font-weight: 600;
    font-size: 32px;
  }

  @if $code == 'h2' {
    line-height: 36px;
    font-weight: 600;
    font-size: 28px;
  }

  @if $code == 'h3' {
    line-height: 32px;
    font-weight: 600;
    font-size: 24px;
  }

  @if $code == 'h4' {
    line-height: 28px;
    font-weight: 600;
    font-size: 20px;
  }

  @if $code == 's1' {
    line-height: 28px;
    font-weight: 600;
    font-size: 20px;
  }

  @if $code == 's2' {
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
  }

  @if $code == 'p1' {
    line-height: 24px;
    font-size: 16px;
  }

  @if $code == 'p2' {
    line-height: 20px;
    font-size: 14px;
  }

  @if $code == 'c1' {
    line-height: 16px;
    font-weight: 600;
    font-size: 12px;
  }

  @if $code == 'c2' {
    line-height: 16px;
    font-size: 12px;
  }
}
