*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  font-family: 'Source Sans Pro', sans-serif;
  min-width: 320px;
  margin: 0;
  background: var(--color-dark-1700);
  color: var(--color-dark-000);
}
