:root {
  --color-primary: #ca1941;

  --color-dark-000: #fff;
  --color-dark-100: #f7f8fb;
  --color-dark-200: #ecf0f6;
  --color-dark-300: #dbe2ed;
  --color-dark-400: #cbd5e3;
  --color-dark-500: #b6b1ca;
  --color-dark-600: #aaa3bf;
  --color-dark-700: #9b93b4;
  --color-dark-800: #8e86a8;
  --color-dark-900: #837ba0;
  --color-dark-1000: #756d93;
  --color-dark-1100: #696186;
  --color-dark-1200: #5d5579;
  --color-dark-1300: #51496b;
  --color-dark-1400: #484063;
  --color-dark-1500: #3f3858;
  --color-dark-1600: #332d48;
  --color-dark-1700: #272239;
  --color-dark-1800: #161223;
  --block-margin: 16px;

  @media screen and (min-width: 447px) {
    --block-margin: 32px;
  }

  @media screen and (min-width: 1329px) {
    --block-margin: 64px;
  }
}
